import React, { useState } from 'react';
import HTMLReactParser from 'html-react-parser';

import { useParams } from 'react-router-dom';
import millify from 'millify';
import { Col, Row, Typography, Select } from 'antd';
import { MoneyCollectOutlined, DollarCircleOutlined, FundOutlined, ExclamationCircleOutlined, StopOutlined, TrophyOutlined , NumberOutlined , CheckOutlined ,ThunderboltOutlined} from '@ant-design/icons';
import { useGetCryptoDetailsQuery, useGetCryptoHistoryQuery } from '../services/cryptoApi';
import LineChart from './LineChart';
const { Title, Text } = Typography;
const { Option } = Select;

const CryptoDetails = () => {
  const { coinID } = useParams();
  console.log(coinID);
  const [timePeriod, settimePeriod] = useState('7d');
  const { data, isFetching } = useGetCryptoDetailsQuery(coinID);
  const { data: coinHistory , isCoinHistoryFetching } = useGetCryptoHistoryQuery({ coinID, timePeriod });
  if (isFetching) return "Loading ...."
  if (isCoinHistoryFetching) return "loading....."
  console.log({ Coinhistory: coinHistory });
  const cryptoDetails = data?.data?.coin;
  console.log(cryptoDetails);
  const time = ['3h', '24h', '7d', '30d', '1y', '3m', '3y', '5y'];
  const stats = [
    { title: 'Price to USD', value: `$ ${cryptoDetails.price && millify(cryptoDetails.price)}`, icon: <DollarCircleOutlined /> },
    { title: 'Rank', value: cryptoDetails.rank, icon: <NumberOutlined /> },
    { title: '24h Volume', value: `$ ${cryptoDetails["24hVolume"] && millify(cryptoDetails["24hVolume"])}`, icon: <ThunderboltOutlined /> },
    { title: 'Market Cap', value: `$ ${cryptoDetails.marketCap && millify(cryptoDetails.marketCap)}`, icon: <DollarCircleOutlined /> },
    { title: 'All-time-high(daily avg.)', value: `$ ${millify(cryptoDetails.allTimeHigh.price)}`, icon: <TrophyOutlined /> },
  ];

  const genericStats = [
    { title: 'Number Of Markets', value: cryptoDetails.numberOfMarkets, icon: <FundOutlined /> },
    { title: 'Number Of Exchanges', value: cryptoDetails.numberOfExchanges, icon: <MoneyCollectOutlined /> },
    { title: 'Total Supply', value: `$ ${millify(cryptoDetails.supply.total)}`, icon: <ExclamationCircleOutlined /> },
    { title: 'Circulating Supply', value: `$ ${millify(cryptoDetails.supply.circulating)}`, icon: <ExclamationCircleOutlined /> },
  ];

  return (
    <Col className="coin-detail-container">
      <Col className="coin-heading-container">
        <Title level={2} className="coin-name">
          {cryptoDetails.name} ({cryptoDetails.slug}) Price
        </Title>
        <p> {cryptoDetails.name} Live price in US dollars. View value statistic, market cap, and supply.</p>
        <Select
          defaultValue="7d"
          className="select-timeperiod"
          placeholder="Select Time Period"
          onChange={(value) => settimePeriod(value)}
        >
          {time.map((date) => <Option key={date}>{date}</Option>)}
        </Select>

        {/* Line Chart Here ..... */}
        <LineChart coinHistory={coinHistory} currentPrice={millify(cryptoDetails.price)} coinName={cryptoDetails.name}></LineChart>


        <Col className='stats-container'>
          <Col className="coin-value-statistics">
            <Col className="coin-value-statistics-heading">
              <Title level={3} className="coin-details-heading">
                {cryptoDetails.name} Value Statistic
              </Title>
              <p> An overview showing the stats of {cryptoDetails.name}.</p>
              {stats.map(({ icon, title, value }) => (
                <Col className='coin-stats'>
                  <Col className="coin-stats-name">
                    <Text>{icon}</Text>
                    <Text>{title}</Text>
                  </Col>
                  <Text className='stats'>{value}</Text>
                </Col>
              )  )}
            </Col>
          </Col>
          <Col className="other-stats-info">
            <Col className="coin-value-statistics-heading">
              <Title level={3} className="coin-details-heading">
                {cryptoDetails.name} other information
              </Title>
              <p> An overview showing the stats of {cryptoDetails.name}.</p>
              {genericStats.map(({ icon, title, value }) => (
                <Col className='coin-stats'>
                  <Col className="coin-stats-name">
                    <Text>{icon}</Text>
                    <Text>{title}</Text>
                  </Col>
                  <Text className='stats'>{value}</Text>
                </Col>
              )  )}
            </Col>
          </Col>
        </Col>
        <Col className="coin-desc-link">
          <Row className="coin-desc">
            <Title level={3} className="coin-details-heading">
              What is {cryptoDetails.name}
              {HTMLReactParser(cryptoDetails.description)}
            </Title>
          </Row>
          <Col className="coin-links">
            <Title level={3} className="coin-details-heading">
              {cryptoDetails.name} Links
            </Title>
            {cryptoDetails.links.map((link) => (
              <Row className="coin-link" key={link.name}>
                <Title level={5} className="link-name">{link.type}</Title>
                <a href={link.url} target="_blank" rel="noreferrer">{link.name}</a>
              </Row>
            ))}
          </Col>
        </Col>
      </Col>
    </Col>
    // <div>Currency  {cryptoDetails.name} </div>
  )
}

export default CryptoDetails
